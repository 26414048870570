<script setup lang="ts">
import { __ } from '@@/bits/intl'
import { authWithReferrerUrl } from '@@/bits/location'
import { isRegistered } from '@@/bits/user_model'
import OzAvatar from '@@/library/v4/components/OzAvatar.vue'
import OzContainedButton, {
  OzContainedButtonColorScheme,
  OzContainedButtonSizePreset,
} from '@@/library/v4/components/OzContainedButton.vue'
import { useAppBarAccountStore } from '@@/pinia/app_bar_account'
import type { User } from '@@/types'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    user: User
    referrerUrl?: string
    darkMode?: boolean | 'auto'
    disabled?: boolean
  }>(),
  {
    referrerUrl: undefined,
    darkMode: 'auto',
    disabled: false,
  },
)

const appBarAccountStore = useAppBarAccountStore()
const { showUserMenu } = appBarAccountStore

const AVATAR_SIZE = 24

const isUserRegistered = computed<boolean>(() => {
  return isRegistered(props.user)
})

const avatarUrl = computed<string | undefined>(() => {
  if (!isUserRegistered.value && !props.disabled && props.referrerUrl) {
    return authWithReferrerUrl(props.referrerUrl)
  }
  return undefined
})

const buttonLabel = computed<string>(() => {
  if (props.disabled) {
    return isUserRegistered.value
      ? __('Logged in as %{user}', { user: props.user.name || props.user.username })
      : __('Not logged in')
  }
  return isUserRegistered.value ? __('Show user menu') : __('Go to sign up page')
})
</script>

<template>
  <OzContainedButton
    :class="disabled && '!cursor-default'"
    :disabled="disabled"
    :dark-mode="darkMode"
    :color-scheme="OzContainedButtonColorScheme.SecondaryClear"
    :size-preset="OzContainedButtonSizePreset.H40px"
    :aria-label="buttonLabel"
    :title="buttonLabel"
    data-testid="appBarAccountAvatar"
    :data-pepin-id="isUserRegistered ? 'app-bar-account-avatar-show-user-menu' : 'app-bar-account-avatar-anonymous'"
    data-pepin-trigger="click"
    :href="avatarUrl"
    @click="isUserRegistered && showUserMenu()"
  >
    <template #icon>
      <OzAvatar
        class="rounded-full"
        :width="AVATAR_SIZE"
        :height="AVATAR_SIZE"
        :src="user.avatar"
        :alt-text-name="user.name || user.username"
      />
    </template>
  </OzContainedButton>
</template>
